import React from 'react';
import PropTypes from 'prop-types';

import getDisplayName from 'utils/getDisplayName';

export default (Layout, layoutProps = {}) => (Component) => {
  // needs to be class to allow diving through it in tests
  class withLayout extends React.PureComponent {
    static getInitialProps = async (ctx) => {
      const layoutInitialProps = Layout.getInitialProps
        ? await Layout.getInitialProps(ctx)
        : {};
      const componentInitialProps = Component.getInitialProps
        ? await Component.getInitialProps(ctx)
        : {};

      return { componentInitialProps, layoutInitialProps };
    };

    static pageTitle = Component.pageTitle;

    static displayName = `withLayout(${getDisplayName(Layout)})(${getDisplayName(Component)})`;

    static defaultProps = {
      componentInitialProps: null,
      layoutInitialProps: null,
    };

    static propTypes = {
      componentInitialProps: PropTypes.shape(),
      layoutInitialProps: PropTypes.shape(),
    };

    render = () => {
      const { componentInitialProps, layoutInitialProps, ...props } = this.props;

      return (
        <Layout {...props} {...layoutInitialProps} {...layoutProps}>
          <Component {...props} {...componentInitialProps} />
        </Layout>
      );
    };
  }

  return withLayout;
};
