import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'ramda';
import { withFormik } from 'formik';

import { AUTH_FLOW, AUTH_CODE_LENGTH } from 'constants/auth';

import yup from 'lib/yupLocalised';

import { handleSubmit } from 'utils/form/handleSubmit';

import { setVisibleAuthFlow, submitUserTwoFactorAuth } from 'state/concepts/session/actions';

import VerificationFlowComponent from './component';

class VerificationFlow extends React.Component {
  static propTypes = {
    setVisibleAuthFlow: PropTypes.func.isRequired,
  };

  handleRecoveryFlow = () => {
    this.props.setVisibleAuthFlow(AUTH_FLOW.recovery);
  }

  render = () => (
    <VerificationFlowComponent
      {...this.props}
      handleRecoveryFlow={this.handleRecoveryFlow}
    />
  )
}

const mapDispatchToProps = {
  onSubmit: submitUserTwoFactorAuth,
  setVisibleAuthFlow,
};

export { VerificationFlow as VerificationFlowContainer };

export default compose(
  connect(null, mapDispatchToProps),
  withFormik({
    mapPropsToValues: (() => ({
      verificationCode: '',
    })),
    handleSubmit,
    validationSchema: yup.object().shape({
      verificationCode: yup.string()
        .min(AUTH_CODE_LENGTH)
        .max(AUTH_CODE_LENGTH)
        .matches('^[0-9]+$', { message: { id: 'yup.string.matches.onlyDigits' } })
        .required({ id: 'yup.mixed.required.verificationCode' }),
    }),
  }),
)(VerificationFlow);
