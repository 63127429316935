import PropTypes from 'prop-types';
import { Field, Form } from 'formik';
import { prop } from 'ramda';
import { FormattedMessage } from 'react-intl';

import SubmitFormButton from 'views/shared/SubmitFormButton';
import Button from 'views/shared/Button';
import InputField from 'views/shared/InputField';
import Alert from 'views/shared/Alert';
import SiteRules from 'views/shared/SiteRules';

const VerificationFlowComponent = ({
  handleRecoveryFlow,
  status,
}) => (
  <>
    <Form className="grow-1">
      <div className="container">
        <div className="row">
          <div className="col-12 col-sm-6 offset-sm-3 col-md-4 offset-md-4">
            <div className="two-fa">
              <img src="/static/images/logo.svg" alt="" className="two-fa__img" />
              {prop('base', status) && (
                <Alert
                  className="auth__alert"
                  message={prop('base', status)}
                />
              )}
              <h1 className="auth__title">
                <FormattedMessage id="login.verificationFlow.title" />
              </h1>
              <p className="auth__caption">
                <FormattedMessage id="login.verificationFlow.viewCodeOnYourDevice" />
              </p>
              <Field
                name="verificationCode"
                component={InputField}
                label={{ id: 'label.authCode' }}
                className="w-full mb-24"
                icon="alert"
              />
              <div className="two-fa__btns">
                <SubmitFormButton
                  className="w-160"
                  text={{ id: 'shared.verify' }}
                />
              </div>
              <div className="two-fa__footer">
                <h2 className="two-fa__subtitle">
                  <FormattedMessage id="login.verificationFlow.havingProblems" />
                </h2>
                <Button
                  onClick={handleRecoveryFlow}
                  kind="link"
                  className="two-fa__link"
                  text={{ id: 'login.verificationFlow.enterRecoveryCode' }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Form>
    <SiteRules />
  </>
);

VerificationFlowComponent.defaultProps = {
  status: undefined,
};

VerificationFlowComponent.propTypes = {
  status: PropTypes.shape(),
  handleRecoveryFlow: PropTypes.func.isRequired,
};

export default VerificationFlowComponent;
