import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'ramda';
import { withFormik } from 'formik';
import yup from 'lib/yupLocalised';

import { handleSubmit } from 'utils/form/handleSubmit';

import { submitUserRecoveryCode } from 'state/concepts/session/actions';

import RecoveryFlowComponent from './component';

// eslint-disable-next-line react/prefer-stateless-function
class RecoveryFlow extends React.Component {
  render() {
    return (
      <RecoveryFlowComponent
        {...this.props}
      />
    );
  }
}

const mapDispatchToProps = {
  onSubmit: submitUserRecoveryCode,
};

export { RecoveryFlow as RecoveryFlowContainer };

export default compose(
  connect(null, mapDispatchToProps),
  withFormik({
    mapPropsToValues: (() => ({
      recoveryCode: '',
    })),
    handleSubmit,
    validationSchema: yup.object().shape({
      recoveryCode: yup.string()
       .max(10)
       .required(),
    }),
  }),
)(RecoveryFlow);
