import PropTypes from 'prop-types';
import { isNil } from 'ramda';

import { AUTH_FLOW } from 'constants/auth';

import LoginForm from 'views/LoginForm';

import RecoveryFlow from './RecoveryFlow';
import VerificationFlow from './VerificationFlow';

const LoginComponent = ({
  authFlow,
  onSubmit,
}) => {
  if (isNil(authFlow)) {
    return (
      <LoginForm
        onSubmit={onSubmit}
        redirectToResetPassword="/reset_password"
      />
    );
  }

  return (
    <>
      {authFlow === AUTH_FLOW.recovery && <RecoveryFlow />}
      {authFlow === AUTH_FLOW.verification && <VerificationFlow />}
    </>
  );
};

LoginComponent.defaultProps = {
  authFlow: null,
};

LoginComponent.propTypes = {
  authFlow: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
};

export default LoginComponent;
