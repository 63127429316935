import PropTypes from 'prop-types';
import { Field, Form } from 'formik';
import { prop } from 'ramda';
import { FormattedMessage } from 'react-intl';

import { CLINIX_PHONE_NUMBER } from 'constants';

import SubmitFormButton from 'views/shared/SubmitFormButton';
import InputField from 'views/shared/InputField';
import SiteRules from 'views/shared/SiteRules';
import Alert from 'views/shared/Alert';

const RecoveryFlowComponent = ({
  status,
}) => (
  <>
    <Form>
      <div className="container">
        <div className="row">
          <div className="col-12 col-sm-6 offset-sm-3 col-md-4 offset-md-4">
            <div className="two-fa">
              <img src="/static/images/logo.svg" alt="" className="two-fa__img" />
              {
              prop('base', status) && (
                <Alert
                  className="auth__alert"
                  message={prop('base', status)}
                />
              )
            }
              <h1 className="auth__title">
                <FormattedMessage id="login.recoveryFlow.title" />
              </h1>
              <p className="auth__caption">
                <FormattedMessage id="login.recoveryFlow.recoveryCodesIfYouLostMobile" />
              </p>
              <Field
                name="recoveryCode"
                component={InputField}
                label={{ id: 'label.recoveryCode' }}
                icon="alert"
                className="w-full mb-24"
              />
              <div className="two-fa__btns">
                <SubmitFormButton
                  text={{ id: 'shared.verify' }}
                  className="w-160"
                />
              </div>
              <div className="two-fa__footer">
                <p className="auth__caption mb-8">
                  <FormattedMessage id="login.recoveryFlow.cantFindVerificationCode" />
                </p>
                <p className="auth__caption">
                  <FormattedMessage
                    id="login.recoveryFlow.callUsAt"
                    values={{
                    phoneNumber: <a href={`tel:${CLINIX_PHONE_NUMBER}`} className="auth__caption--mark">{CLINIX_PHONE_NUMBER}</a>,
                  }}
                  />
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Form>
    <SiteRules />
  </>
);

RecoveryFlowComponent.defaultProps = {
  status: undefined,
};

RecoveryFlowComponent.propTypes = {
  status: PropTypes.shape(),
};

export default RecoveryFlowComponent;
