import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'ramda';

import { submitUserLogin } from 'state/concepts/session/actions';
import { showNotification as showNotificationAction } from 'state/notifications/actions';
import { visibleAuthFlowSelector } from 'state/concepts/session/selectors';

import withLayout from 'views/layouts/withLayout';
import GuestLayout from 'views/layouts/Guest';

import LoginComponent from './component';

class Login extends React.Component {
  static pageTitle = { id: 'pageTitle.login' };

  static getInitialProps = ({ query: { isConfirmNewEmail } }) => ({ isConfirmNewEmail });

  static propTypes = {
    showNotification: PropTypes.func.isRequired,
    isConfirmNewEmail: PropTypes.bool,
  };

  static defaultProps = {
    isConfirmNewEmail: false,
  };

  componentDidMount() {
    const { showNotification, isConfirmNewEmail } = this.props;

    if (isConfirmNewEmail) {
      showNotification({ messageObject: { id: 'notifications.pleaseLoginToConfirmYourEmail' } });
    }
  }

  render = () => (
    <LoginComponent
      {...this.props}
    />
  );
}

const mapStateToProps = (state) => ({
  authFlow: visibleAuthFlowSelector(state),
});

const mapDispatchToProps = {
  onSubmit: submitUserLogin,
  showNotification: showNotificationAction,
};

export { Login as LoginContainer };
export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withLayout(GuestLayout, { isGrey: false }),
)(Login);
